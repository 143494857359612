/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * esrs:KeyActionCoverageMember
 * @export
 * @enum {string}
 */

export const IKeyActionCoverageEnum = {
    UpstreamValueChainMember: 'esrs:UpstreamValueChainMember',
    GeographiesMember: 'esrs:GeographiesMember',
    AffectedStakeholderGroupsMember: 'esrs:AffectedStakeholderGroupsMember',
    DownstreamValueChainMember: 'esrs:DownstreamValueChainMember',
    UndertakingsActivitiesMember: 'esrs:UndertakingsActivitiesMember'
} as const;

export type IKeyActionCoverageEnum = typeof IKeyActionCoverageEnum[keyof typeof IKeyActionCoverageEnum];




/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * esrs:LayerInMitigationHierarchyForBiodiversityAndEcosystemsMember
 * @export
 * @enum {string}
 */

export const ILayerInMitigationHierarchyForBiodiversityAndEcosystemsEnum = {
    AvoidanceMemberEsrsAvoidanceThroughSiteSelectionMember: 'esrs:AvoidanceMember.esrs:AvoidanceThroughSiteSelectionMember',
    AvoidanceMemberEsrsAvoidanceThroughProjectDesignMember: 'esrs:AvoidanceMember.esrs:AvoidanceThroughProjectDesignMember',
    AvoidanceMemberEsrsAvoidanceThroughSchedulingMember: 'esrs:AvoidanceMember.esrs:AvoidanceThroughSchedulingMember',
    MinimisationMember: 'esrs:MinimisationMember',
    RestorationAndRehabilitationMember: 'esrs:RestorationAndRehabilitationMember',
    CompensationOrOffsetsMember: 'esrs:CompensationOrOffsetsMember'
} as const;

export type ILayerInMitigationHierarchyForBiodiversityAndEcosystemsEnum = typeof ILayerInMitigationHierarchyForBiodiversityAndEcosystemsEnum[keyof typeof ILayerInMitigationHierarchyForBiodiversityAndEcosystemsEnum];




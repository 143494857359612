import { CircularProgress } from "@mui/material";
import { UseQueryResult } from "@tanstack/react-query";
import { ReactNode } from "react";
import { ErrorTextComponent } from "./error-text.component";
import { CenteredCircularProgress } from "./centered-circular-progress.component";

interface IQueryWrapperProps<T> {
  query: UseQueryResult<T> | null;
  build: (result: T) => ReactNode;
  loadingOverride?: (loadingResult: UseQueryResult | null) => ReactNode;
  errorOverride?: (errorResult: UseQueryResult) => ReactNode;
  centerLoader?: boolean;
}

export function QueryWrapper<T>({
  query,
  build,
  loadingOverride,
  errorOverride,
  centerLoader,
}: IQueryWrapperProps<T>) {
  if (query === null || query.isLoading) {
    return (
      loadingOverride?.(query) ??
      (centerLoader ? <CenteredCircularProgress /> : <CircularProgress />)
    );
  } else if (query.error) {
    return errorOverride?.(query) ?? <ErrorTextComponent error={query.error} />;
  }
  return build(query.data!);
}

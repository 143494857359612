/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * esrs:LayerInMitigationHierarchyForPollutionMember
 * @export
 * @enum {string}
 */

export const ILayerInMitigationHierarchyForPollutionEnum = {
    PollutionAvoidancePreventionOfPollutionAtSourceMember: 'esrs:PollutionAvoidancePreventionOfPollutionAtSourceMember',
    PollutionReductionMinimisationOfPollutionMember: 'esrs:PollutionReductionMinimisationOfPollutionMember',
    RestorationRegenerationAndTransformationOfEcosystemsWherePollutionHasOccurredControlOfImpactsFromRegularActivitiesAndIncidentsMember: 'esrs:RestorationRegenerationAndTransformationOfEcosystemsWherePollutionHasOccurredControlOfImpactsFromRegularActivitiesAndIncidentsMember'
} as const;

export type ILayerInMitigationHierarchyForPollutionEnum = typeof ILayerInMitigationHierarchyForPollutionEnum[keyof typeof ILayerInMitigationHierarchyForPollutionEnum];




import { FC, useMemo } from "react";
import { IInputParameterValueMetaData } from "@netcero/netcero-core-api-client";
import {
  alwaysArray,
  DataEntryObjectInputParameterValueDefinitionForBoolean,
  DataEntryObjectInputParameterValueDefinitionForValueWithUnit,
  DataEntryObjectInputParameterValueDefinitionForNestedOptions,
  DataEntryObjectInputParameterValueDefinitionForOptions,
  DataEntryObjectInputParameterValueDefinitionForTextSingle,
  OptionalDataEntryObjectInputParameterValueDefinition,
} from "@netcero/netcero-common";
import { useTranslateBooleanInputValue } from "../../../value-acquisition/input-components/boolean-input.component";
import { FormatUtilities } from "../../../common/utilities/format.utilities";
import { useGenerateOptionsLookup } from "../../../value-acquisition/hooks/options-lookup.hook";
import { useGenerateNestedOptionsLookup } from "../../../value-acquisition/hooks/nested-options-lookup.hook";
import { LineClampTypographyWithTooltip } from "../../../common/components/line-clamp-typography.component";

const NO_VALUE = "-";

interface IRenderTableValueReadonlyProps {
  valueMetaData: IInputParameterValueMetaData;
  value: OptionalDataEntryObjectInputParameterValueDefinition;
}

const resolveAndRenderOptionValues = (values: string[], lookup: Record<string, string>): string => {
  const resolved = values
    .map((v) => lookup[v])
    // v should never be undefined in the first place, but just to be safe
    // and, in order to not lose this wisdom: "better safe than unprotected"
    .filter((v) => v !== undefined);

  return resolved.join(", ");
};

export const RenderTableValueReadonly: FC<IRenderTableValueReadonlyProps> = ({
  valueMetaData,
  value,
}) => {
  const translateBooleanInputValue = useTranslateBooleanInputValue();
  const generateOptionsLookup = useGenerateOptionsLookup();
  const generateNestedOptionsLookup = useGenerateNestedOptionsLookup();

  const renderedValue = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }

    switch (valueMetaData.type) {
      case "text":
        return value as DataEntryObjectInputParameterValueDefinitionForTextSingle;
      case "number":
        if (valueMetaData.valueLimits.isPercentage) {
          return FormatUtilities.formatPercentage(+value, valueMetaData.valueLimits.precision);
        } else {
          return FormatUtilities.formatNumber(+value, {
            maximumFractionDigits: valueMetaData.valueLimits.precision,
          });
        }
      case "boolean":
        return translateBooleanInputValue(
          value as DataEntryObjectInputParameterValueDefinitionForBoolean,
        );
      case "value-with-unit": {
        const { value: num, unit } =
          value as DataEntryObjectInputParameterValueDefinitionForValueWithUnit;
        return `${FormatUtilities.formatNumber(num)} ${unit}`;
      }
      case "options": {
        // generate lookup for nested values
        const lookup = generateOptionsLookup(valueMetaData);
        // read current values
        const typedValue = alwaysArray(
          value as DataEntryObjectInputParameterValueDefinitionForOptions,
        );
        // render selected values
        return resolveAndRenderOptionValues(typedValue, lookup);
      }
      case "nested-options": {
        // generate lookup for nested values
        const lookup = generateNestedOptionsLookup(valueMetaData);
        // read current values
        const typedValue = alwaysArray(
          value as DataEntryObjectInputParameterValueDefinitionForNestedOptions,
        );
        // render selected values
        return resolveAndRenderOptionValues(typedValue, lookup);
      }
      // Future type have to be added once they will be in use
      default:
        return "Unsupported";
    }
  }, [
    generateNestedOptionsLookup,
    generateOptionsLookup,
    translateBooleanInputValue,
    value,
    valueMetaData,
  ]);

  return (
    <LineClampTypographyWithTooltip variant="body2" maxLines={2}>
      {renderedValue ?? NO_VALUE}
    </LineClampTypographyWithTooltip>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const IMdrUnit = {
    G: 'g',
    Kg: 'kg',
    T: 't',
    J: 'J',
    KJ: 'kJ',
    Mj: 'MJ',
    KWh: 'kWh',
    Mwh: 'MWh',
    Gwh: 'GWh',
    Twh: 'TWh',
    JPerMmEuro: 'J_per_mm_euro',
    KJPerMmEuro: 'kJ_per_mm_euro',
    MjPerMmEuro: 'MJ_per_mm_euro',
    KWhPerMmEuro: 'kWh_per_mm_euro',
    MwhPerMmEuro: 'MWh_per_mm_euro',
    GwhPerMmEuro: 'GWh_per_mm_euro',
    TwhPerMmEuro: 'TWh_per_mm_euro',
    TCo2e: 'tCO2e',
    KtCo2e: 'ktCO2e',
    MtCo2e: 'MtCO2e',
    GtCo2e: 'GtCO2e',
    TCo2ePerMmEuro: 'tCO2e_per_mm_euro',
    KtCo2ePerMmEuro: 'ktCO2e_per_mm_euro',
    MtCo2ePerMmEuro: 'MtCO2e_per_mm_euro',
    GtCo2ePerMmEuro: 'GtCO2e_per_mm_euro',
    L: 'l',
    M3: 'm3',
    LPerMmEuro: 'l_per_mm_euro',
    M3PerMmEuro: 'm3_per_mm_euro',
    M2: 'm2',
    Km2: 'km2',
    Ha: 'ha',
    Euro: 'euro'
} as const;

export type IMdrUnit = typeof IMdrUnit[keyof typeof IMdrUnit];




import { Button, ButtonProps } from "@mui/material";
import { FC, ReactNode } from "react";
import { BaseAsyncActionDialog } from "./base-async-action.dialog";
import {
  DialogCommonProps,
  IDialogWithOptionalError,
  OverrideDialogProps,
} from "../types/common.types";

export interface IOptionsDialogOption {
  label: string;
  onClick: () => void;
  buttonProps?: ButtonProps;
}

export interface IOptionsDialogProps extends DialogCommonProps, IDialogWithOptionalError {
  title?: ReactNode;
  content: ReactNode;
  /** Utilize the OptionsDialogOptionFactory to create options. */
  options: IOptionsDialogOption[];
  loading?: boolean;
  dialogProps?: OverrideDialogProps;
}

export const OptionsDialog: FC<IOptionsDialogProps> = ({
  open,
  onClose,
  title,
  content,
  options,
  loading,
  error,
  dialogProps,
}) => {
  return (
    <BaseAsyncActionDialog
      open={open}
      onClose={onClose}
      title={title}
      loading={!!loading}
      error={error}
      content={content}
      actions={options.map((option) => (
        <Button key={option.label} onClick={option.onClick} {...option.buttonProps}>
          {option.label}
        </Button>
      ))}
      dialogProps={dialogProps}
    />
  );
};

/**
 * Factory to create options for the OptionsDialog
 */
export class OptionsDialogOptionFactory {
  public static createConfirmOption(
    label: string,
    onClick: VoidFunction,
    buttonProps?: ButtonProps,
  ): IOptionsDialogOption {
    return {
      label,
      onClick,
      buttonProps: { variant: "contained", ...buttonProps },
    };
  }

  public static createDeleteOption(
    label: string,
    onClick: VoidFunction,
    buttonProps?: ButtonProps,
  ): IOptionsDialogOption {
    return OptionsDialogOptionFactory.createConfirmOption(label, onClick, buttonProps);
  }

  public static createCancelOption(
    label: string,
    onClick: VoidFunction,
    buttonProps?: ButtonProps,
  ): IOptionsDialogOption {
    return {
      label,
      onClick,
      buttonProps: { variant: "text", ...buttonProps },
    };
  }
}

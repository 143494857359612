/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * esrs:ListOfMetricsAbstract
 * @export
 * @enum {string}
 */

export const IListOfMetricsEnum = {
    AbsoluteValueOfLocationBasedScope2GreenhouseGasEmissionsReduction: 'esrs:AbsoluteValueOfLocationBasedScope2GreenhouseGasEmissionsReduction',
    AbsoluteValueOfMarketBasedScope2GreenhouseGasEmissionsReduction: 'esrs:AbsoluteValueOfMarketBasedScope2GreenhouseGasEmissionsReduction',
    AbsoluteValueOfScope1GreenhouseGasEmissionsReduction: 'esrs:AbsoluteValueOfScope1GreenhouseGasEmissionsReduction',
    AbsoluteValueOfScope3GreenhouseGasEmissionsReduction: 'esrs:AbsoluteValueOfScope3GreenhouseGasEmissionsReduction',
    AbsoluteValueOfTotalGreenhouseGasEmissionsReduction: 'esrs:AbsoluteValueOfTotalGreenhouseGasEmissionsReduction',
    AchievedGhgEmissionReductions: 'esrs:AchievedGHGEmissionReductions',
    AdjustingItemsToAssetsAtMaterialPhysicalRiskInReconciliationWithFinancialStatement: 'esrs:AdjustingItemsToAssetsAtMaterialPhysicalRiskInReconciliationWithFinancialStatement',
    AdjustingItemsToAssetsAtMaterialTransitionRiskInReconciliationWithFinancialStatement: 'esrs:AdjustingItemsToAssetsAtMaterialTransitionRiskInReconciliationWithFinancialStatement',
    AdjustingItemsToLiabilitiesAtMaterialTransitionRiskInReconciliationWithFinancialStatement: 'esrs:AdjustingItemsToLiabilitiesAtMaterialTransitionRiskInReconciliationWithFinancialStatement',
    AdjustingItemsToNetRevenueAtMaterialPhysicalRiskInReconciliationWithFinancialStatement: 'esrs:AdjustingItemsToNetRevenueAtMaterialPhysicalRiskInReconciliationWithFinancialStatement',
    AdjustingItemsToNetRevenueAtMaterialTransitionRiskInReconciliationWithFinancialStatement: 'esrs:AdjustingItemsToNetRevenueAtMaterialTransitionRiskInReconciliationWithFinancialStatement',
    AmountOfCarbonCreditsOutsideValueChainPlannedToBeCancelledInFuture: 'esrs:AmountOfCarbonCreditsOutsideValueChainPlannedToBeCancelledInFuture',
    AmountOfCarbonCreditsOutsideValueChainThatAreVerifiedAgainstRecognisedQualityStandardsAndCancelled: 'esrs:AmountOfCarbonCreditsOutsideValueChainThatAreVerifiedAgainstRecognisedQualityStandardsAndCancelled',
    AmountOfFinesForViolationOfAnticorruptionAndAntibriberyLaws: 'esrs:AmountOfFinesForViolationOfAnticorruptionAndAntibriberyLaws',
    AmountOfFinesPenaltiesAndCompensationForDamagesAsResultOfIncidentesAndComplaints: 'esrs:AmountOfFinesPenaltiesAndCompensationForDamagesAsResultOfIncidentesAndComplaints',
    AmountOfFinesPenaltiesAndCompensationForDamagesForSevereHumanRightsIncidentsConnectedToOwnWorkforce: 'esrs:AmountOfFinesPenaltiesAndCompensationForDamagesForSevereHumanRightsIncidentsConnectedToOwnWorkforce',
    AmountOfInternalAndExternalLobbyingExpenses: 'esrs:AmountOfInternalAndExternalLobbyingExpenses',
    AmountOfSubstancesOfConcernThatAreGeneratedOrUsedDuringProductionOrThatAreProcured: 'esrs:AmountOfSubstancesOfConcernThatAreGeneratedOrUsedDuringProductionOrThatAreProcured',
    AmountOfSubstancesOfConcernThatLeaveFacilitiesAsEmissions: 'esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsEmissions',
    AmountOfSubstancesOfConcernThatLeaveFacilitiesAsEmissionsAsProductsOrAsPartOfProductsOrServices: 'esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsEmissionsAsProductsOrAsPartOfProductsOrServices',
    AmountOfSubstancesOfConcernThatLeaveFacilitiesAsPartOfProducts: 'esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsPartOfProducts',
    AmountOfSubstancesOfConcernThatLeaveFacilitiesAsProducts: 'esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsProducts',
    AmountOfSubstancesOfConcernThatLeaveFacilitiesAsServices: 'esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsServices',
    AmountOfSubstancesOfVeryHighConcernThatAreGeneratedOrUsedDuringProductionOrThatAreProcured: 'esrs:AmountOfSubstancesOfVeryHighConcernThatAreGeneratedOrUsedDuringProductionOrThatAreProcured',
    AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsEmissions: 'esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsEmissions',
    AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsEmissionsAsProductsOrAsPartOfProductsOrServices: 'esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsEmissionsAsProductsOrAsPartOfProductsOrServices',
    AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsPartOfProducts: 'esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsPartOfProducts',
    AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsProducts: 'esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsProducts',
    AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsServices: 'esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsServices',
    AmountPaidForMembershipToLobbyingAssociations: 'esrs:AmountPaidForMembershipToLobbyingAssociations',
    AnnualRemunerationRatio: 'esrs:AnnualRemunerationRatio',
    AreaCoveredByInvasiveAlienSpecies: 'esrs:AreaCoveredByInvasiveAlienSpecies',
    AreaOfSitesOwnedLeasedOrManagedInOrNearProtectedAreasOrKeyBiodiversityAreasThatUndertakingIsNegativelyAffecting: 'esrs:AreaOfSitesOwnedLeasedOrManagedInOrNearProtectedAreasOrKeyBiodiversityAreasThatUndertakingIsNegativelyAffecting',
    Assets: 'esrs:Assets',
    AssetsAtAcuteMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions: 'esrs:AssetsAtAcuteMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions',
    AssetsAtChronicMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions: 'esrs:AssetsAtChronicMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions',
    AssetsAtMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions: 'esrs:AssetsAtMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions',
    AssetsAtMaterialTransitionRiskBeforeConsideringClimateMitigationActions: 'esrs:AssetsAtMaterialTransitionRiskBeforeConsideringClimateMitigationActions',
    AverageNumberOfDaysToPayInvoiceFromDateWhenContractualOrStatutoryTermOfPaymentStartsToBeCalculated: 'esrs:AverageNumberOfDaysToPayInvoiceFromDateWhenContractualOrStatutoryTermOfPaymentStartsToBeCalculated',
    AverageNumberOfTrainingHoursPerEmployeeAndOrNonemployee: 'esrs:AverageNumberOfTrainingHoursPerEmployeeAndOrNonemployee',
    BiogenicEmissionsOfCo2FromCombustionOrBiodegradationOfBiomassNotIncludedInScope1GhgEmissions: 'esrs:BiogenicEmissionsOfCO2FromCombustionOrBiodegradationOfBiomassNotIncludedInScope1GHGEmissions',
    BiogenicEmissionsOfCo2FromCombustionOrBiodegradationOfBiomassNotIncludedInScope2GhgEmissions: 'esrs:BiogenicEmissionsOfCO2FromCombustionOrBiodegradationOfBiomassNotIncludedInScope2GHGEmissions',
    BiogenicEmissionsOfCo2FromCombustionOrBiodegradationOfBiomassThatOccurInUpstreamAndDownstreamValueChainNotIncludedInScope3GhgEmissions: 'esrs:BiogenicEmissionsOfCO2FromCombustionOrBiodegradationOfBiomassThatOccurInUpstreamAndDownstreamValueChainNotIncludedInScope3GHGEmissions',
    BoardsGenderDiversityRatio: 'esrs:BoardsGenderDiversityRatio',
    CapitalExpendituresCapExInConjunctionWithMajorIncidentsAndDeposits: 'esrs:CapitalExpendituresCapExInConjunctionWithMajorIncidentsAndDeposits',
    CarbonPriceAppliedForEachMetricTonneOfGreenhouseGasEmission: 'esrs:CarbonPriceAppliedForEachMetricTonneOfGreenhouseGasEmission',
    CarryingAmountOfAssetsAtMaterialPhysicalRisk: 'esrs:CarryingAmountOfAssetsAtMaterialPhysicalRisk',
    CarryingAmountOfAssetsAtMaterialTransitionRisk: 'esrs:CarryingAmountOfAssetsAtMaterialTransitionRisk',
    CarryingAmountOfLiabilitiesAtMaterialTransitionRisk: 'esrs:CarryingAmountOfLiabilitiesAtMaterialTransitionRisk',
    CarryingAmountOfRealEstateAssets: 'esrs:CarryingAmountOfRealEstateAssets',
    CarryingAmountOfRealEstateAssetsForWhichEnergyConsumptionIsBasedOnInternalEstimates: 'esrs:CarryingAmountOfRealEstateAssetsForWhichEnergyConsumptionIsBasedOnInternalEstimates',
    ConsumptionOfPurchasedOrAcquiredElectricityHeatSteamAndCoolingFromRenewableSources: 'esrs:ConsumptionOfPurchasedOrAcquiredElectricityHeatSteamAndCoolingFromRenewableSources',
    ConsumptionOfPurchasedOrAcquiredElectricityHeatSteamOrCoolingFromFossilSources: 'esrs:ConsumptionOfPurchasedOrAcquiredElectricityHeatSteamOrCoolingFromFossilSources',
    ConsumptionOfSelfgeneratedNonfuelRenewableEnergy: 'esrs:ConsumptionOfSelfgeneratedNonfuelRenewableEnergy',
    DurationOfClassroomTraining: 'esrs:DurationOfClassroomTraining',
    DurationOfComputerbasedTraining: 'esrs:DurationOfComputerbasedTraining',
    DurationOfVoluntaryComputerbasedTraining: 'esrs:DurationOfVoluntaryComputerbasedTraining',
    EmissionsToAir: 'esrs:EmissionsToAir',
    EmissionsToSoil: 'esrs:EmissionsToSoil',
    EmissionsToWater: 'esrs:EmissionsToWater',
    EnergyConsumptionFromFossilSources: 'esrs:EnergyConsumptionFromFossilSources',
    EnergyConsumptionFromNuclearSources: 'esrs:EnergyConsumptionFromNuclearSources',
    EnergyConsumptionFromRenewableSources: 'esrs:EnergyConsumptionFromRenewableSources',
    EnergyConsumptionRelatedToOwnOperations: 'esrs:EnergyConsumptionRelatedToOwnOperations',
    EnergyIntensityFromActivitiesInHighClimateImpactSectorsTotalEnergyConsumptionPerNetRevenue: 'esrs:EnergyIntensityFromActivitiesInHighClimateImpactSectorsTotalEnergyConsumptionPerNetRevenue',
    EstimatedAmountOfPotentiallyStrandedAssets: 'esrs:EstimatedAmountOfPotentiallyStrandedAssets',
    ExpectedCostSavingsFromClimateChangeAdaptationActions: 'esrs:ExpectedCostSavingsFromClimateChangeAdaptationActions',
    ExpectedCostSavingsFromClimateChangeMitigationActions: 'esrs:ExpectedCostSavingsFromClimateChangeMitigationActions',
    ExpectedGhgEmissionReductions: 'esrs:ExpectedGHGEmissionReductions',
    FinancialAndInKindPoliticalContributionsMade: 'esrs:FinancialAndInKindPoliticalContributionsMade',
    FinancialPoliticalContributionsMade: 'esrs:FinancialPoliticalContributionsMade',
    FinancialResourcesAllocatedToActionPlanCapEx: 'esrs:FinancialResourcesAllocatedToActionPlanCapEx',
    FinancialResourcesAllocatedToActionPlanOpEx: 'esrs:FinancialResourcesAllocatedToActionPlanOpEx',
    FinancingEffectsDirectAndIndirectCostsOfBiodiversityOffsets: 'esrs:FinancingEffectsDirectAndIndirectCostsOfBiodiversityOffsets',
    FuelConsumptionFromCoalAndCoalProducts: 'esrs:FuelConsumptionFromCoalAndCoalProducts',
    FuelConsumptionFromCrudeOilAndPetroleumProducts: 'esrs:FuelConsumptionFromCrudeOilAndPetroleumProducts',
    FuelConsumptionFromNaturalGas: 'esrs:FuelConsumptionFromNaturalGas',
    FuelConsumptionFromOtherFossilSources: 'esrs:FuelConsumptionFromOtherFossilSources',
    FuelConsumptionFromRenewableSources: 'esrs:FuelConsumptionFromRenewableSources',
    GenderPayGap: 'esrs:GenderPayGap',
    GhgEmissionsAssociatedWithRemovalActivity: 'esrs:GHGEmissionsAssociatedWithRemovalActivity',
    GhgEmissionsIntensityLocationbasedTotalGhgEmissionsPerNetRevenue: 'esrs:GHGEmissionsIntensityLocationbasedTotalGHGEmissionsPerNetRevenue',
    GhgEmissionsIntensityMarketbasedTotalGhgEmissionsPerNetRevenue: 'esrs:GHGEmissionsIntensityMarketbasedTotalGHGEmissionsPerNetRevenue',
    GhgRemovalsAndStorage: 'esrs:GHGRemovalsAndStorage',
    GrossGreenhouseGasEmissions: 'esrs:GrossGreenhouseGasEmissions',
    GrossLocationBasedScope2GreenhouseGasEmissions: 'esrs:GrossLocationBasedScope2GreenhouseGasEmissions',
    GrossMarketBasedScope2GreenhouseGasEmissions: 'esrs:GrossMarketBasedScope2GreenhouseGasEmissions',
    GrossScope1GreenhouseGasEmissions: 'esrs:GrossScope1GreenhouseGasEmissions',
    GrossScope3GreenhouseGasEmissions: 'esrs:GrossScope3GreenhouseGasEmissions',
    HazardousWaste: 'esrs:HazardousWaste',
    IncreaseDecreaseInNetRevenueFromLowcarbonProductsAndServicesOrAdaptationSolutionsToWhichUndertakingHasOrMayHaveAccess: 'esrs:IncreaseDecreaseInNetRevenueFromLowcarbonProductsAndServicesOrAdaptationSolutionsToWhichUndertakingHasOrMayHaveAccess',
    IncreaseDecreaseInWaterStorage: 'esrs:IncreaseDecreaseInWaterStorage',
    InkindPoliticalContributionsMade: 'esrs:InkindPoliticalContributionsMade',
    IntensityValueOfLocationBasedScope2GreenhouseGasEmissionsReduction: 'esrs:IntensityValueOfLocationBasedScope2GreenhouseGasEmissionsReduction',
    IntensityValueOfMarketBasedScope2GreenhouseGasEmissionsReduction: 'esrs:IntensityValueOfMarketBasedScope2GreenhouseGasEmissionsReduction',
    IntensityValueOfScope1GreenhouseGasEmissionsReduction: 'esrs:IntensityValueOfScope1GreenhouseGasEmissionsReduction',
    IntensityValueOfScope3GreenhouseGasEmissionsReduction: 'esrs:IntensityValueOfScope3GreenhouseGasEmissionsReduction',
    IntensityValueOfTotalGreenhouseGasEmissionsReduction: 'esrs:IntensityValueOfTotalGreenhouseGasEmissionsReduction',
    Liabilities: 'esrs:Liabilities',
    LiabilitiesFromMaterialTransitionRisksThatMayHaveToBeRecognisedInFinancialStatements: 'esrs:LiabilitiesFromMaterialTransitionRisksThatMayHaveToBeRecognisedInFinancialStatements',
    LocationBasedGreenhouseGasEmissions: 'esrs:LocationBasedGreenhouseGasEmissions',
    MarketBasedGreenhouseGasEmissions: 'esrs:MarketBasedGreenhouseGasEmissions',
    MicroplasticsGenerated: 'esrs:MicroplasticsGenerated',
    MicroplasticsGeneratedOrUsed: 'esrs:MicroplasticsGeneratedOrUsed',
    MicroplasticsUsed: 'esrs:MicroplasticsUsed',
    MonetisedGrossScope1And2GhgEmissions: 'esrs:MonetisedGrossScope1And2GHGEmissions',
    MonetisedTotalGhgEmissions: 'esrs:MonetisedTotalGHGEmissions',
    NatureorientedAreaOffSite: 'esrs:NatureorientedAreaOffSite',
    NatureorientedAreaOnSite: 'esrs:NatureorientedAreaOnSite',
    NetRevenueAtMaterialPhysicalRisk: 'esrs:NetRevenueAtMaterialPhysicalRisk',
    NetRevenueAtMaterialTransitionRisk: 'esrs:NetRevenueAtMaterialTransitionRisk',
    NetRevenueOtherThanUsedToCalculateGhgIntensity: 'esrs:NetRevenueOtherThanUsedToCalculateGHGIntensity',
    NetRevenueUsedToCalculateGhgIntensity: 'esrs:NetRevenueUsedToCalculateGHGIntensity',
    NonrecycledWaste: 'esrs:NonrecycledWaste',
    NonrenewableEnergyProduction: 'esrs:NonrenewableEnergyProduction',
    NumberOfCasesOfRecordableWorkrelatedIllHealth: 'esrs:NumberOfCasesOfRecordableWorkrelatedIllHealth',
    NumberOfComplaintsFiledThroughChannelsForPeopleInOwnWorkforceToRaiseConcerns: 'esrs:NumberOfComplaintsFiledThroughChannelsForPeopleInOwnWorkforceToRaiseConcerns',
    NumberOfComplaintsFiledToNationalContactPointsForOecdMultinationalEnterprises: 'esrs:NumberOfComplaintsFiledToNationalContactPointsForOECDMultinationalEnterprises',
    NumberOfComplaintsReceivedFromConsumersAndEndusers: 'esrs:NumberOfComplaintsReceivedFromConsumersAndEndusers',
    NumberOfConfirmedIncidentsInWhichOwnWorkersWereDismissedOrDisciplinedForCorruptionOrBriberyrelatedIncidents: 'esrs:NumberOfConfirmedIncidentsInWhichOwnWorkersWereDismissedOrDisciplinedForCorruptionOrBriberyrelatedIncidents',
    NumberOfConfirmedIncidentsOfCorruptionOrBribery: 'esrs:NumberOfConfirmedIncidentsOfCorruptionOrBribery',
    NumberOfConfirmedIncidentsRelatingToContractsWithBusinessPartnersThatWereTerminatedOrNotRenewedDueToViolationsRelatedToCorruptionOrBribery: 'esrs:NumberOfConfirmedIncidentsRelatingToContractsWithBusinessPartnersThatWereTerminatedOrNotRenewedDueToViolationsRelatedToCorruptionOrBribery',
    NumberOfConvictionsForViolationOfAnticorruptionAndAntibriberyLaws: 'esrs:NumberOfConvictionsForViolationOfAnticorruptionAndAntibriberyLaws',
    NumberOfDaysLostToWorkrelatedInjuriesAndFatalitiesFromWorkrelatedAccidentsWorkrelatedIllHealthAndFatalitiesFromIllHealth: 'esrs:NumberOfDaysLostToWorkrelatedInjuriesAndFatalitiesFromWorkrelatedAccidentsWorkrelatedIllHealthAndFatalitiesFromIllHealth',
    NumberOfEmissionAllowancesStoredFromPreviousAllowancesAtBeginningOfReportingPeriod: 'esrs:NumberOfEmissionAllowancesStoredFromPreviousAllowancesAtBeginningOfReportingPeriod',
    NumberOfEmployeesHeadcountAtEndOfPeriod: 'esrs:NumberOfEmployeesHeadcountAtEndOfPeriod',
    NumberOfEmployeesHeadCountAtTopManagementLevelAtEndOfPeriod: 'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelAtEndOfPeriod',
    NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod: 'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod',
    NumberOfEmployeesHeadCountBetween30And50YearsOldAtEndOfPeriod: 'esrs:NumberOfEmployeesHeadCountBetween30And50YearsOldAtEndOfPeriod',
    NumberOfEmployeesHeadCountBetween30And50YearsOldDuringPeriod: 'esrs:NumberOfEmployeesHeadCountBetween30And50YearsOldDuringPeriod',
    NumberOfEmployeesHeadCountDuringPeriod: 'esrs:NumberOfEmployeesHeadCountDuringPeriod',
    NumberOfEmployeesHeadCountOrFulltimeEquivalentAtEndOfPeriod: 'esrs:NumberOfEmployeesHeadCountOrFulltimeEquivalentAtEndOfPeriod',
    NumberOfEmployeesHeadCountOrFulltimeEquivalentDuringPeriod: 'esrs:NumberOfEmployeesHeadCountOrFulltimeEquivalentDuringPeriod',
    NumberOfEmployeesHeadCountOver50YearsOldAtEndOfPeriod: 'esrs:NumberOfEmployeesHeadCountOver50YearsOldAtEndOfPeriod',
    NumberOfEmployeesHeadCountOver50YearsOldDuringPeriod: 'esrs:NumberOfEmployeesHeadCountOver50YearsOldDuringPeriod',
    NumberOfEmployeesHeadCountUnder30YearsOldAtEndOfPeriod: 'esrs:NumberOfEmployeesHeadCountUnder30YearsOldAtEndOfPeriod',
    NumberOfEmployeesHeadCountUnder30YearsOldDuringPeriod: 'esrs:NumberOfEmployeesHeadCountUnder30YearsOldDuringPeriod',
    NumberOfEmployeesInCountriesWith50OrMoreEmployeesRepresentingAtLeast10PercentOfTotalNumberOfEmployeesAtEndOfPeriod: 'esrs:NumberOfEmployeesInCountriesWith50OrMoreEmployeesRepresentingAtLeast10PercentOfTotalNumberOfEmployeesAtEndOfPeriod',
    NumberOfEmployeesInCountriesWith50OrMoreEmployeesRepresentingAtLeast10PercentOfTotalNumberOfEmployeesDuringPeriod: 'esrs:NumberOfEmployeesInCountriesWith50OrMoreEmployeesRepresentingAtLeast10PercentOfTotalNumberOfEmployeesDuringPeriod',
    NumberOfEmployeesReceivingAnticorruptionAndAntibriberyTrainingDuringPeriod: 'esrs:NumberOfEmployeesReceivingAnticorruptionAndAntibriberyTrainingDuringPeriod',
    NumberOfEmployeesWhoHaveLeftUndertakingDuringPeriod: 'esrs:NumberOfEmployeesWhoHaveLeftUndertakingDuringPeriod',
    NumberOfExecutiveMembers: 'esrs:NumberOfExecutiveMembers',
    NumberOfFatalitiesAsResultOfWorkrelatedIllHealthOfOtherWorkersWorkingOnUndertakingsSites: 'esrs:NumberOfFatalitiesAsResultOfWorkrelatedIllHealthOfOtherWorkersWorkingOnUndertakingsSites',
    NumberOfFatalitiesAsResultOfWorkrelatedInjuriesAndWorkrelatedIllHealthOfOtherWorkersWorkingOnUndertakingsSites: 'esrs:NumberOfFatalitiesAsResultOfWorkrelatedInjuriesAndWorkrelatedIllHealthOfOtherWorkersWorkingOnUndertakingsSites',
    NumberOfFatalitiesAsResultOfWorkrelatedInjuriesOfOtherWorkersWorkingOnUndertakingsSites: 'esrs:NumberOfFatalitiesAsResultOfWorkrelatedInjuriesOfOtherWorkersWorkingOnUndertakingsSites',
    NumberOfFatalitiesInOwnWorkforceAsResultOfWorkrelatedIllHealth: 'esrs:NumberOfFatalitiesInOwnWorkforceAsResultOfWorkrelatedIllHealth',
    NumberOfFatalitiesInOwnWorkforceAsResultOfWorkrelatedInjuries: 'esrs:NumberOfFatalitiesInOwnWorkforceAsResultOfWorkrelatedInjuries',
    NumberOfFatalitiesInOwnWorkforceAsResultOfWorkrelatedInjuriesAndWorkrelatedIllHealth: 'esrs:NumberOfFatalitiesInOwnWorkforceAsResultOfWorkrelatedInjuriesAndWorkrelatedIllHealth',
    NumberOfIncidentsOfDiscrimination: 'esrs:NumberOfIncidentsOfDiscrimination',
    NumberOfInvasiveAlienSpecies: 'esrs:NumberOfInvasiveAlienSpecies',
    NumberOfLegalProceedingsOutstandingForLatePayments: 'esrs:NumberOfLegalProceedingsOutstandingForLatePayments',
    NumberOfNonemployeesInOwnWorkforceAtEndOfPeriod: 'esrs:NumberOfNonemployeesInOwnWorkforceAtEndOfPeriod',
    NumberOfNonemployeesInOwnWorkforceDuringPeriod: 'esrs:NumberOfNonemployeesInOwnWorkforceDuringPeriod',
    NumberOfNonemployeesInOwnWorkforcePeopleProvidedByUndertakingsPrimarilyEngagedInEmploymentActivitiesAtEndOfPeriod: 'esrs:NumberOfNonemployeesInOwnWorkforcePeopleProvidedByUndertakingsPrimarilyEngagedInEmploymentActivitiesAtEndOfPeriod',
    NumberOfNonemployeesInOwnWorkforcePeopleProvidedByUndertakingsPrimarilyEngagedInEmploymentActivitiesDuringPeriod: 'esrs:NumberOfNonemployeesInOwnWorkforcePeopleProvidedByUndertakingsPrimarilyEngagedInEmploymentActivitiesDuringPeriod',
    NumberOfNonemployeesInOwnWorkforceSelfemployedPeopleAtEndOfPeriod: 'esrs:NumberOfNonemployeesInOwnWorkforceSelfemployedPeopleAtEndOfPeriod',
    NumberOfNonemployeesInOwnWorkforceSelfemployedPeopleDuringPeriod: 'esrs:NumberOfNonemployeesInOwnWorkforceSelfemployedPeopleDuringPeriod',
    NumberOfNonexecutiveMembers: 'esrs:NumberOfNonexecutiveMembers',
    NumberOfRecordableWorkrelatedAccidents: 'esrs:NumberOfRecordableWorkrelatedAccidents',
    NumberOfScope1GhgEmissionAllowancesWithinRegulatedEmissionTradingSchemes: 'esrs:NumberOfScope1GHGEmissionAllowancesWithinRegulatedEmissionTradingSchemes',
    NumberOfSevereHumanRightsIncidentsConnectedToOwnWorkforce: 'esrs:NumberOfSevereHumanRightsIncidentsConnectedToOwnWorkforce',
    NumberOfSevereHumanRightsIncidentsConnectedToOwnWorkforceThatAreNonrespectOfUnGuidingPrinciplesOnBusinessAndHumanRightsIloDeclarationOnFundamentalPrinciplesAndRightsAtWorkOrOecdGuidelinesForMultinationalEnterprises: 'esrs:NumberOfSevereHumanRightsIncidentsConnectedToOwnWorkforceThatAreNonrespectOfUnGuidingPrinciplesOnBusinessAndHumanRightsIloDeclarationOnFundamentalPrinciplesAndRightsAtWorkOrOecdGuidelinesForMultinationalEnterprises',
    NumberOfSevereHumanRightsIncidentsWhereUndertakingPlayedRoleSecuringRemedyForThoseAffected: 'esrs:NumberOfSevereHumanRightsIncidentsWhereUndertakingPlayedRoleSecuringRemedyForThoseAffected',
    NumberOfSitesOwnedLeasedOrManagedInOrNearProtectedAreasOrKeyBiodiversityAreasThatUndertakingIsNegativelyAffecting: 'esrs:NumberOfSitesOwnedLeasedOrManagedInOrNearProtectedAreasOrKeyBiodiversityAreasThatUndertakingIsNegativelyAffecting',
    OperatingExpendituresOpExInConjunctionWithMajorIncidentsAndDeposits: 'esrs:OperatingExpendituresOpExInConjunctionWithMajorIncidentsAndDeposits',
    PercentageOfApproximateGrossScope1GreenhouseGasEmissionsCoveredByInternalCarbonPricingScheme: 'esrs:PercentageOfApproximateGrossScope1GreenhouseGasEmissionsCoveredByInternalCarbonPricingScheme',
    PercentageOfApproximateGrossScope2GreenhouseGasEmissionsCoveredByInternalCarbonPricingScheme: 'esrs:PercentageOfApproximateGrossScope2GreenhouseGasEmissionsCoveredByInternalCarbonPricingScheme',
    PercentageOfApproximateGrossScope3GreenhouseGasEmissionsCoveredByInternalCarbonPricingScheme: 'esrs:PercentageOfApproximateGrossScope3GreenhouseGasEmissionsCoveredByInternalCarbonPricingScheme',
    PercentageOfAssetsAtMaterialPhysicalRiskAddressedByClimateChangeAdaptationActions: 'esrs:PercentageOfAssetsAtMaterialPhysicalRiskAddressedByClimateChangeAdaptationActions',
    PercentageOfAssetsAtMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions: 'esrs:PercentageOfAssetsAtMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions',
    PercentageOfAssetsAtMaterialTransitionRiskAddressedByClimateChangeMitigationActions: 'esrs:PercentageOfAssetsAtMaterialTransitionRiskAddressedByClimateChangeMitigationActions',
    PercentageOfAssetsAtMaterialTransitionRiskBeforeConsideringClimateMitigationActions: 'esrs:PercentageOfAssetsAtMaterialTransitionRiskBeforeConsideringClimateMitigationActions',
    PercentageOfBiologicalMaterialsUsedToManufactureProductsAndServicesThatAreSustainablySourced: 'esrs:PercentageOfBiologicalMaterialsUsedToManufactureProductsAndServicesThatAreSustainablySourced',
    PercentageOfCarbonCreditsCancelledForRecognisedQualityStandard: 'esrs:PercentageOfCarbonCreditsCancelledForRecognisedQualityStandard',
    PercentageOfCarbonCreditsCancelledFromProjectsInEuropeanUnion: 'esrs:PercentageOfCarbonCreditsCancelledFromProjectsInEuropeanUnion',
    PercentageOfCarbonCreditsCancelledFromReductionProjects: 'esrs:PercentageOfCarbonCreditsCancelledFromReductionProjects',
    PercentageOfCarbonCreditsCancelledFromRemovalProjects: 'esrs:PercentageOfCarbonCreditsCancelledFromRemovalProjects',
    PercentageOfCarbonCreditsCancelledThatQualifiesAsCorrespondingAdjustment: 'esrs:PercentageOfCarbonCreditsCancelledThatQualifiesAsCorrespondingAdjustment',
    PercentageOfContractualInstrumentsScope2GhgEmissions: 'esrs:PercentageOfContractualInstrumentsScope2GHGEmissions',
    PercentageOfContractualInstrumentsUsedForSaleAndPurchaseOfEnergyBundledWithAttributesAboutEnergyGenerationInRelationToScope2GhgEmissions: 'esrs:PercentageOfContractualInstrumentsUsedForSaleAndPurchaseOfEnergyBundledWithAttributesAboutEnergyGenerationInRelationToScope2GHGEmissions',
    PercentageOfContractualInstrumentsUsedForSaleAndPurchaseOfUnbundledEnergyAttributeClaimsInRelationToScope2GhgEmissions: 'esrs:PercentageOfContractualInstrumentsUsedForSaleAndPurchaseOfUnbundledEnergyAttributeClaimsInRelationToScope2GHGEmissions',
    PercentageOfDataObtainedFromBestEstimatesWaterConsumption: 'esrs:PercentageOfDataObtainedFromBestEstimatesWaterConsumption',
    PercentageOfDataObtainedFromDirectMeasurementWaterConsumption: 'esrs:PercentageOfDataObtainedFromDirectMeasurementWaterConsumption',
    PercentageOfDataObtainedFromSamplingAndExtrapolationWaterConsumption: 'esrs:PercentageOfDataObtainedFromSamplingAndExtrapolationWaterConsumption',
    PercentageOfEmployeesAndOrNonemployeesPaidBelowApplicableAdequateWageBenchmark: 'esrs:PercentageOfEmployeesAndOrNonemployeesPaidBelowApplicableAdequateWageBenchmark',
    PercentageOfEmployeesAndOrNonemployeesThatParticipatedInRegularPerformanceAndCareerDevelopmentReviews: 'esrs:PercentageOfEmployeesAndOrNonemployeesThatParticipatedInRegularPerformanceAndCareerDevelopmentReviews',
    PercentageOfEmployeesAtTopManagementLevel: 'esrs:PercentageOfEmployeesAtTopManagementLevel',
    PercentageOfEmployeesBetween30And50YearsOld: 'esrs:PercentageOfEmployeesBetween30And50YearsOld',
    PercentageOfEmployeesEntitledToTakeFamilyrelatedLeaves: 'esrs:PercentageOfEmployeesEntitledToTakeFamilyrelatedLeaves',
    PercentageOfEmployeesOver50YearsOld: 'esrs:PercentageOfEmployeesOver50YearsOld',
    PercentageOfEmployeesUnder30YearsOld: 'esrs:PercentageOfEmployeesUnder30YearsOld',
    PercentageOfEmployeeTurnover: 'esrs:PercentageOfEmployeeTurnover',
    PercentageOfEnergyConsumptionFromNuclearSourcesInTotalEnergyConsumption: 'esrs:PercentageOfEnergyConsumptionFromNuclearSourcesInTotalEnergyConsumption',
    PercentageOfEntitledEmployeesThatTookFamilyrelatedLeave: 'esrs:PercentageOfEntitledEmployeesThatTookFamilyrelatedLeave',
    PercentageOfEstimatedShareOfPotentiallyStrandedAssetsOfTotalAssetsAtMaterialTransitionRisk: 'esrs:PercentageOfEstimatedShareOfPotentiallyStrandedAssetsOfTotalAssetsAtMaterialTransitionRisk',
    PercentageOfFossilSourcesInTotalEnergyConsumption: 'esrs:PercentageOfFossilSourcesInTotalEnergyConsumption',
    PercentageOfFunctionsatriskCoveredByAnticorruptionAndAntibriberyTrainingProgrammes: 'esrs:PercentageOfFunctionsatriskCoveredByAnticorruptionAndAntibriberyTrainingProgrammes',
    PercentageOfGrossScope3GreenhouseGasEmissionsCalculatedUsingPrimaryDataObtainedFromSuppliersOrOtherValueChainPartners: 'esrs:PercentageOfGrossScope3GreenhouseGasEmissionsCalculatedUsingPrimaryDataObtainedFromSuppliersOrOtherValueChainPartners',
    PercentageOfIndependentBoardMembers: 'esrs:PercentageOfIndependentBoardMembers',
    PercentageOfLocationBasedScope2GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear: 'esrs:PercentageOfLocationBasedScope2GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear',
    PercentageOfMarketbasedScope2GhgEmissionsLinkedToPurchasedElectricityBundledWithInstruments: 'esrs:PercentageOfMarketbasedScope2GHGEmissionsLinkedToPurchasedElectricityBundledWithInstruments',
    PercentageOfMarketBasedScope2GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear: 'esrs:PercentageOfMarketBasedScope2GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear',
    PercentageOfMembersOfAdministrativeManagementAndSupervisoryBodies: 'esrs:PercentageOfMembersOfAdministrativeManagementAndSupervisoryBodies',
    PercentageOfNetRevenueFromBusinessActivitiesAtMaterialPhysicalRisk: 'esrs:PercentageOfNetRevenueFromBusinessActivitiesAtMaterialPhysicalRisk',
    PercentageOfNetRevenueFromBusinessActivitiesAtMaterialTransitionRisk: 'esrs:PercentageOfNetRevenueFromBusinessActivitiesAtMaterialTransitionRisk',
    PercentageOfNetRevenueFromCustomersOperatingInCoalrelatedActivities: 'esrs:PercentageOfNetRevenueFromCustomersOperatingInCoalrelatedActivities',
    PercentageOfNetRevenueFromCustomersOperatingInGasrelatedActivities: 'esrs:PercentageOfNetRevenueFromCustomersOperatingInGasrelatedActivities',
    PercentageOfNetRevenueFromCustomersOperatingInOilrelatedActivities: 'esrs:PercentageOfNetRevenueFromCustomersOperatingInOilrelatedActivities',
    PercentageOfNetRevenueMadeWithProductsAndServicesThatAreOrThatContainSubstancesOfConcern: 'esrs:PercentageOfNetRevenueMadeWithProductsAndServicesThatAreOrThatContainSubstancesOfConcern',
    PercentageOfNetRevenueMadeWithProductsAndServicesThatAreOrThatContainSubstancesOfVeryHighConcern: 'esrs:PercentageOfNetRevenueMadeWithProductsAndServicesThatAreOrThatContainSubstancesOfVeryHighConcern',
    PercentageOfNonemployeesInOwnWorkforceWhoseWorkingConditionsAndTermsOfEmploymentAreDeterminedOrInfluencedByCollectiveBargainingAgreements: 'esrs:PercentageOfNonemployeesInOwnWorkforceWhoseWorkingConditionsAndTermsOfEmploymentAreDeterminedOrInfluencedByCollectiveBargainingAgreements',
    PercentageOfNonrecycledWaste: 'esrs:PercentageOfNonrecycledWaste',
    PercentageOfOwnWorkersCoveredByHealthAndSafetyManagementSystemBasedOnLegalRequirementsAndOrRecognisedStandardsOrGuidelinesAndWhichHasBeenInternallyAuditedAndOrAuditedOrCertifiedByExternalParty: 'esrs:PercentageOfOwnWorkersCoveredByHealthAndSafetyManagementSystemBasedOnLegalRequirementsAndOrRecognisedStandardsOrGuidelinesAndWhichHasBeenInternallyAuditedAndOrAuditedOrCertifiedByExternalParty',
    PercentageOfPaymentsAlignedWithStandardPaymentTerms: 'esrs:PercentageOfPaymentsAlignedWithStandardPaymentTerms',
    PercentageOfPeopleInOwnWorkforceWhoAreCoveredByHealthAndSafetyManagementSystemBasedOnLegalRequirementsAndOrRecognisedStandardsOrGuidelines: 'esrs:PercentageOfPeopleInOwnWorkforceWhoAreCoveredByHealthAndSafetyManagementSystemBasedOnLegalRequirementsAndOrRecognisedStandardsOrGuidelines',
    PercentageOfPersonsWithDisabilitiesAmongstEmployees: 'esrs:PercentageOfPersonsWithDisabilitiesAmongstEmployees',
    PercentageOfRemunerationRecognisedInCurrentPeriodThatIsLinkedToClimateRelatedConsiderations: 'esrs:PercentageOfRemunerationRecognisedInCurrentPeriodThatIsLinkedToClimateRelatedConsiderations',
    PercentageOfRenewableSourcesInTotalEnergyConsumption: 'esrs:PercentageOfRenewableSourcesInTotalEnergyConsumption',
    PercentageOfScope1GreenhouseGasEmissionsFromRegulatedEmissionTradingSchemes: 'esrs:PercentageOfScope1GreenhouseGasEmissionsFromRegulatedEmissionTradingSchemes',
    PercentageOfScope1GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear: 'esrs:PercentageOfScope1GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear',
    PercentageOfScope1GreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction: 'esrs:PercentageOfScope1GreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction',
    PercentageOfScope2LocationbasedGreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction: 'esrs:PercentageOfScope2LocationbasedGreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction',
    PercentageOfScope2MarketbasedGreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction: 'esrs:PercentageOfScope2MarketbasedGreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction',
    PercentageOfScope3GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear: 'esrs:PercentageOfScope3GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear',
    PercentageOfScope3GreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction: 'esrs:PercentageOfScope3GreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction',
    PercentageOfSecondaryReusedOrRecycledComponentsSecondaryIntermediaryProductsAndSecondaryMaterialsUsedToManufactureProductsAndServices: 'esrs:PercentageOfSecondaryReusedOrRecycledComponentsSecondaryIntermediaryProductsAndSecondaryMaterialsUsedToManufactureProductsAndServices',
    PercentageOfTargetRelatedToLocationBasedScope2GreenhouseGasEmissions: 'esrs:PercentageOfTargetRelatedToLocationBasedScope2GreenhouseGasEmissions',
    PercentageOfTargetRelatedToLocationBasedScope2GreenhouseGasEmissionsAsOfEmissionsOfBaseYear: 'esrs:PercentageOfTargetRelatedToLocationBasedScope2GreenhouseGasEmissionsAsOfEmissionsOfBaseYear',
    PercentageOfTargetRelatedToLocationBasedScope2GreenhouseGasEmissionsIntensityValue: 'esrs:PercentageOfTargetRelatedToLocationBasedScope2GreenhouseGasEmissionsIntensityValue',
    PercentageOfTargetRelatedToMarketBasedScope2GreenhouseGasEmissions: 'esrs:PercentageOfTargetRelatedToMarketBasedScope2GreenhouseGasEmissions',
    PercentageOfTargetRelatedToMarketBasedScope2GreenhouseGasEmissionsAsOfEmissionsOfBaseYear: 'esrs:PercentageOfTargetRelatedToMarketBasedScope2GreenhouseGasEmissionsAsOfEmissionsOfBaseYear',
    PercentageOfTargetRelatedToMarketBasedScope2GreenhouseGasEmissionsIntensityValue: 'esrs:PercentageOfTargetRelatedToMarketBasedScope2GreenhouseGasEmissionsIntensityValue',
    PercentageOfTargetRelatedToScope1GreenhouseGasEmissions: 'esrs:PercentageOfTargetRelatedToScope1GreenhouseGasEmissions',
    PercentageOfTargetRelatedToScope1GreenhouseGasEmissionsAsOfEmissionsOfBaseYear: 'esrs:PercentageOfTargetRelatedToScope1GreenhouseGasEmissionsAsOfEmissionsOfBaseYear',
    PercentageOfTargetRelatedToScope1GreenhouseGasEmissionsIntensityValue: 'esrs:PercentageOfTargetRelatedToScope1GreenhouseGasEmissionsIntensityValue',
    PercentageOfTargetRelatedToScope3GreenhouseGasEmissions: 'esrs:PercentageOfTargetRelatedToScope3GreenhouseGasEmissions',
    PercentageOfTargetRelatedToScope3GreenhouseGasEmissionsAsOfEmissionsOfBaseYear: 'esrs:PercentageOfTargetRelatedToScope3GreenhouseGasEmissionsAsOfEmissionsOfBaseYear',
    PercentageOfTargetRelatedToScope3GreenhouseGasEmissionsIntensityValue: 'esrs:PercentageOfTargetRelatedToScope3GreenhouseGasEmissionsIntensityValue',
    PercentageOfTargetRelatedToTotalGreenhouseGasEmissions: 'esrs:PercentageOfTargetRelatedToTotalGreenhouseGasEmissions',
    PercentageOfTargetRelatedToTotalGreenhouseGasEmissionsAsOfEmissionsOfBaseYear: 'esrs:PercentageOfTargetRelatedToTotalGreenhouseGasEmissionsAsOfEmissionsOfBaseYear',
    PercentageOfTargetRelatedToTotalGreenhouseGasEmissionsIntensityValue: 'esrs:PercentageOfTargetRelatedToTotalGreenhouseGasEmissionsIntensityValue',
    PercentageOfTotalEmissionsOfPollutantsToSoilOccurringInAreasAtWaterRisk: 'esrs:PercentageOfTotalEmissionsOfPollutantsToSoilOccurringInAreasAtWaterRisk',
    PercentageOfTotalEmissionsOfPollutantsToSoilOccurringInAreasOfHighwaterStress: 'esrs:PercentageOfTotalEmissionsOfPollutantsToSoilOccurringInAreasOfHighwaterStress',
    PercentageOfTotalEmissionsOfPollutantsToWaterOccurringInAreasAtWaterRisk: 'esrs:PercentageOfTotalEmissionsOfPollutantsToWaterOccurringInAreasAtWaterRisk',
    PercentageOfTotalEmissionsOfPollutantsToWaterOccurringInAreasOfHighwaterStress: 'esrs:PercentageOfTotalEmissionsOfPollutantsToWaterOccurringInAreasOfHighwaterStress',
    PercentageOfTotalEmployeesCoveredByCollectiveBargainingAgreements: 'esrs:PercentageOfTotalEmployeesCoveredByCollectiveBargainingAgreements',
    PercentageOfTotalGreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear: 'esrs:PercentageOfTotalGreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear',
    PercentageOfVariableRemunerationDependentOnSustainabilityrelatedTargetsAndOrImpacts: 'esrs:PercentageOfVariableRemunerationDependentOnSustainabilityrelatedTargetsAndOrImpacts',
    PotentialFutureLiabilitiesBasedOnExistingContractualAgreementsAssociatedWithCarbonCreditsPlannedToBeCancelledInNearFuture: 'esrs:PotentialFutureLiabilitiesBasedOnExistingContractualAgreementsAssociatedWithCarbonCreditsPlannedToBeCancelledInNearFuture',
    PotentialMarketSizeOfLowcarbonProductsAndServicesOrAdaptationSolutionsToWhichUndertakingHasOrMayHaveAccess: 'esrs:PotentialMarketSizeOfLowcarbonProductsAndServicesOrAdaptationSolutionsToWhichUndertakingHasOrMayHaveAccess',
    ProductsAndTechnicalAndBiologicalMaterialsUsed: 'esrs:ProductsAndTechnicalAndBiologicalMaterialsUsed',
    ProvisionsForEnvironmentalProtectionAndRemediationCosts: 'esrs:ProvisionsForEnvironmentalProtectionAndRemediationCosts',
    RadioactiveWaste: 'esrs:RadioactiveWaste',
    RateOfRecordableWorkrelatedAccidents: 'esrs:RateOfRecordableWorkrelatedAccidents',
    RemunerationRatioAdjustedForPurchasingPowerDifferencesBetweenCountries: 'esrs:RemunerationRatioAdjustedForPurchasingPowerDifferencesBetweenCountries',
    RenewableEnergyProduction: 'esrs:RenewableEnergyProduction',
    Revenue: 'esrs:Revenue',
    RevenueFromActivitiesInHighClimateImpactSectors: 'esrs:RevenueFromActivitiesInHighClimateImpactSectors',
    RevenueFromActivitiesOtherThanInHighClimateImpactSectors: 'esrs:RevenueFromActivitiesOtherThanInHighClimateImpactSectors',
    RevenueFromBusinessActivitiesAtMaterialPhysicalRisk: 'esrs:RevenueFromBusinessActivitiesAtMaterialPhysicalRisk',
    RevenueFromBusinessActivitiesAtMaterialTransitionRisk: 'esrs:RevenueFromBusinessActivitiesAtMaterialTransitionRisk',
    RevenueFromChemicalsProduction: 'esrs:RevenueFromChemicalsProduction',
    RevenueFromCoal: 'esrs:RevenueFromCoal',
    RevenueFromControversialWeapons: 'esrs:RevenueFromControversialWeapons',
    RevenueFromCultivationAndOrProductionOfTobacco: 'esrs:RevenueFromCultivationAndOrProductionOfTobacco',
    RevenueFromCustomersOperatingInCoalRelatedActivities: 'esrs:RevenueFromCustomersOperatingInCoalRelatedActivities',
    RevenueFromCustomersOperatingInGasRelatedActivities: 'esrs:RevenueFromCustomersOperatingInGasRelatedActivities',
    RevenueFromCustomersOperatingInOilRelatedActivities: 'esrs:RevenueFromCustomersOperatingInOilRelatedActivities',
    RevenueFromFossilFuelCoalOilAndGasSector: 'esrs:RevenueFromFossilFuelCoalOilAndGasSector',
    RevenueFromGas: 'esrs:RevenueFromGas',
    RevenueFromOil: 'esrs:RevenueFromOil',
    RevenueFromTaxonomyalignedEconomicActivitiesRelatedToFossilGas: 'esrs:RevenueFromTaxonomyalignedEconomicActivitiesRelatedToFossilGas',
    Reversals: 'esrs:Reversals',
    SealedArea: 'esrs:SealedArea',
    SecondaryReusedOrRecycledComponentsSecondaryIntermediaryProductsAndSecondaryMaterialsUsedToManufactureProductsAndServices: 'esrs:SecondaryReusedOrRecycledComponentsSecondaryIntermediaryProductsAndSecondaryMaterialsUsedToManufactureProductsAndServices',
    SignificantCapExForCoalrelatedEconomicActivities: 'esrs:SignificantCapExForCoalrelatedEconomicActivities',
    SignificantCapExForGasrelatedEconomicActivities: 'esrs:SignificantCapExForGasrelatedEconomicActivities',
    SignificantCapExForOilrelatedEconomicActivities: 'esrs:SignificantCapExForOilrelatedEconomicActivities',
    UseOfLandArea: 'esrs:UseOfLandArea',
    WasteGenerated: 'esrs:WasteGenerated',
    WasteGeneratedDirectedToDisposal: 'esrs:WasteGeneratedDirectedToDisposal',
    WasteGeneratedDivertedFromDisposal: 'esrs:WasteGeneratedDivertedFromDisposal',
    WaterConsumption: 'esrs:WaterConsumption',
    WaterConsumptionInAreasAtWaterRisk: 'esrs:WaterConsumptionInAreasAtWaterRisk',
    WaterConsumptionInAreasOfHighwaterStress: 'esrs:WaterConsumptionInAreasOfHighwaterStress',
    WaterDischarges: 'esrs:WaterDischarges',
    WaterIntensityTotalWaterConsumptionPerNetRevenue: 'esrs:WaterIntensityTotalWaterConsumptionPerNetRevenue',
    WaterRecycledAndReused: 'esrs:WaterRecycledAndReused',
    WaterStored: 'esrs:WaterStored',
    WaterWithdrawals: 'esrs:WaterWithdrawals'
} as const;

export type IListOfMetricsEnum = typeof IListOfMetricsEnum[keyof typeof IListOfMetricsEnum];




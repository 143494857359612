/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * esrs:LayerInWasteHierarchyMember
 * @export
 * @enum {string}
 */

export const ILayerInWasteHierarchyEnum = {
    PreventionMember: 'esrs:PreventionMember',
    PreparationForReuseMember: 'esrs:PreparationForReuseMember',
    RecyclingMember: 'esrs:RecyclingMember',
    OtherRecoveryMember: 'esrs:OtherRecoveryMember',
    DisposalMember: 'esrs:DisposalMember'
} as const;

export type ILayerInWasteHierarchyEnum = typeof ILayerInWasteHierarchyEnum[keyof typeof ILayerInWasteHierarchyEnum];




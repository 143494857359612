/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IAction } from '../models';
// @ts-ignore
import { IBaseActionData } from '../models';
// @ts-ignore
import { ICreatePolicy409Response } from '../models';
// @ts-ignore
import { ICreateReferenceBudget404Response } from '../models';
// @ts-ignore
import { IDeleteAction404Response } from '../models';
// @ts-ignore
import { IGetActionsResponse } from '../models';
// @ts-ignore
import { IUpdateAction404Response } from '../models';
// @ts-ignore
import { IUpdatePolicy404Response } from '../models';
/**
 * ActionsApi - axios parameter creator
 * @export
 */
export const ActionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new action for the given DEO.
         * @summary Creates a new action for the given DEO.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {IBaseActionData} [iBaseActionData] Create a new action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAction: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseActionData?: IBaseActionData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createAction', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('createAction', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('createAction', 'dataEntryObjectId', dataEntryObjectId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/actions`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseActionData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the specified action for the specified organization and recording period.
         * @summary Deletes the specified action for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} actionId The id of the action to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAction: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, actionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteAction', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('deleteAction', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('deleteAction', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'actionId' is not null or undefined
            assertParamExists('deleteAction', 'actionId', actionId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/actions/{actionId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"actionId"}}`, encodeURIComponent(String(actionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all actions for the specified organization and recording period.
         * @summary Returns all actions for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActions: async (organizationId: string, recordingPeriodId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getActions', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('getActions', 'recordingPeriodId', recordingPeriodId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/actions`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the specified action for the specified organization and recording period.
         * @summary Updates the specified action for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} actionId The id of the action to use.
         * @param {IBaseActionData} [iBaseActionData] Update an existing action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAction: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, actionId: string, iBaseActionData?: IBaseActionData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateAction', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('updateAction', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('updateAction', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'actionId' is not null or undefined
            assertParamExists('updateAction', 'actionId', actionId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/actions/{actionId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"actionId"}}`, encodeURIComponent(String(actionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseActionData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionsApi - functional programming interface
 * @export
 */
export const ActionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new action for the given DEO.
         * @summary Creates a new action for the given DEO.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {IBaseActionData} [iBaseActionData] Create a new action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAction(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseActionData?: IBaseActionData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAction(organizationId, recordingPeriodId, dataEntryObjectId, iBaseActionData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the specified action for the specified organization and recording period.
         * @summary Deletes the specified action for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} actionId The id of the action to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAction(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, actionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAction(organizationId, recordingPeriodId, dataEntryObjectId, actionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all actions for the specified organization and recording period.
         * @summary Returns all actions for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActions(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetActionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActions(organizationId, recordingPeriodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the specified action for the specified organization and recording period.
         * @summary Updates the specified action for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} actionId The id of the action to use.
         * @param {IBaseActionData} [iBaseActionData] Update an existing action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAction(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, actionId: string, iBaseActionData?: IBaseActionData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAction(organizationId, recordingPeriodId, dataEntryObjectId, actionId, iBaseActionData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionsApi - factory interface
 * @export
 */
export const ActionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionsApiFp(configuration)
    return {
        /**
         * Creates a new action for the given DEO.
         * @summary Creates a new action for the given DEO.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {IBaseActionData} [iBaseActionData] Create a new action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAction(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseActionData?: IBaseActionData, options?: any): AxiosPromise<IAction> {
            return localVarFp.createAction(organizationId, recordingPeriodId, dataEntryObjectId, iBaseActionData, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the specified action for the specified organization and recording period.
         * @summary Deletes the specified action for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} actionId The id of the action to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAction(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, actionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAction(organizationId, recordingPeriodId, dataEntryObjectId, actionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all actions for the specified organization and recording period.
         * @summary Returns all actions for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActions(organizationId: string, recordingPeriodId: string, options?: any): AxiosPromise<IGetActionsResponse> {
            return localVarFp.getActions(organizationId, recordingPeriodId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the specified action for the specified organization and recording period.
         * @summary Updates the specified action for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} actionId The id of the action to use.
         * @param {IBaseActionData} [iBaseActionData] Update an existing action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAction(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, actionId: string, iBaseActionData?: IBaseActionData, options?: any): AxiosPromise<IAction> {
            return localVarFp.updateAction(organizationId, recordingPeriodId, dataEntryObjectId, actionId, iBaseActionData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActionsApi - interface
 * @export
 * @interface ActionsApi
 */
export interface ActionsApiInterface {
    /**
     * Creates a new action for the given DEO.
     * @summary Creates a new action for the given DEO.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {IBaseActionData} [iBaseActionData] Create a new action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApiInterface
     */
    createAction(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseActionData?: IBaseActionData, options?: AxiosRequestConfig): AxiosPromise<IAction>;

    /**
     * Deletes the specified action for the specified organization and recording period.
     * @summary Deletes the specified action for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} actionId The id of the action to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApiInterface
     */
    deleteAction(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, actionId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns all actions for the specified organization and recording period.
     * @summary Returns all actions for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApiInterface
     */
    getActions(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig): AxiosPromise<IGetActionsResponse>;

    /**
     * Updates the specified action for the specified organization and recording period.
     * @summary Updates the specified action for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} actionId The id of the action to use.
     * @param {IBaseActionData} [iBaseActionData] Update an existing action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApiInterface
     */
    updateAction(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, actionId: string, iBaseActionData?: IBaseActionData, options?: AxiosRequestConfig): AxiosPromise<IAction>;

}

/**
 * ActionsApi - object-oriented interface
 * @export
 * @class ActionsApi
 * @extends {BaseAPI}
 */
export class ActionsApi extends BaseAPI implements ActionsApiInterface {
    /**
     * Creates a new action for the given DEO.
     * @summary Creates a new action for the given DEO.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {IBaseActionData} [iBaseActionData] Create a new action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public createAction(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseActionData?: IBaseActionData, options?: AxiosRequestConfig) {
        return ActionsApiFp(this.configuration).createAction(organizationId, recordingPeriodId, dataEntryObjectId, iBaseActionData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the specified action for the specified organization and recording period.
     * @summary Deletes the specified action for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} actionId The id of the action to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public deleteAction(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, actionId: string, options?: AxiosRequestConfig) {
        return ActionsApiFp(this.configuration).deleteAction(organizationId, recordingPeriodId, dataEntryObjectId, actionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all actions for the specified organization and recording period.
     * @summary Returns all actions for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public getActions(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig) {
        return ActionsApiFp(this.configuration).getActions(organizationId, recordingPeriodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the specified action for the specified organization and recording period.
     * @summary Updates the specified action for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} actionId The id of the action to use.
     * @param {IBaseActionData} [iBaseActionData] Update an existing action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public updateAction(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, actionId: string, iBaseActionData?: IBaseActionData, options?: AxiosRequestConfig) {
        return ActionsApiFp(this.configuration).updateAction(organizationId, recordingPeriodId, dataEntryObjectId, actionId, iBaseActionData, options).then((request) => request(this.axios, this.basePath));
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { IEnum } from './ienum';

/**
 * 
 * @export
 * @interface IInputParameterValueMetaDataNumberStaticUnit
 */
export interface IInputParameterValueMetaDataNumberStaticUnit {
    /**
     * Identifies the unit for the export
     * @type {IEnum}
     * @memberof IInputParameterValueMetaDataNumberStaticUnit
     */
    'identifier': IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum;
}

export const IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum = {
    KgPerYear: 'kg_per_year'
} as const;

export type IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum = typeof IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum[keyof typeof IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum];



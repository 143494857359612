/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * esrs:DecarbonisationLeverMember
 * @export
 * @enum {string}
 */

export const IDecarbonisationLeverTypeEnum = {
    EnergyEfficiencyAndConsumptionReductionMember: 'esrs:EnergyEfficiencyAndConsumptionReductionMember',
    MaterialEfficiencyAndConsumptionReductionMember: 'esrs:MaterialEfficiencyAndConsumptionReductionMember',
    FuelSwitchingMember: 'esrs:FuelSwitchingMember',
    ElectrificationMember: 'esrs:ElectrificationMember',
    UseOfRenewableEnergyMember: 'esrs:UseOfRenewableEnergyMember',
    PhaseOutSubstitutionOrModificationOfProductMember: 'esrs:PhaseOutSubstitutionOrModificationOfProductMember',
    PhaseOutSubstitutionOrModificationOfProcessMember: 'esrs:PhaseOutSubstitutionOrModificationOfProcessMember',
    SupplyChainDecarbonisationMember: 'esrs:SupplyChainDecarbonisationMember',
    OtherDecarbonisationLeversMember: 'esrs:OtherDecarbonisationLeversMember'
} as const;

export type IDecarbonisationLeverTypeEnum = typeof IDecarbonisationLeverTypeEnum[keyof typeof IDecarbonisationLeverTypeEnum];




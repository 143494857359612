/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ICreatePolicy409Response } from '../models';
// @ts-ignore
import { ICreateReferenceBudget404Response } from '../models';
// @ts-ignore
import { IGetPoliciesResponse } from '../models';
// @ts-ignore
import { IListDistributionCriteria404Response } from '../models';
// @ts-ignore
import { IPolicy } from '../models';
// @ts-ignore
import { IPolicyData } from '../models';
// @ts-ignore
import { IUpdatePolicy404Response } from '../models';
/**
 * PoliciesApi - axios parameter creator
 * @export
 */
export const PoliciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new policy for the given DEO.
         * @summary Creates a new policy for the given DEO.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {IPolicyData} [iPolicyData] Used to create a new policy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPolicy: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iPolicyData?: IPolicyData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createPolicy', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('createPolicy', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('createPolicy', 'dataEntryObjectId', dataEntryObjectId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/policies`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iPolicyData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the specified policy for the specified organization and recording period.
         * @summary Deletes the specified policy for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} policyId The id of the policy to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePolicy: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, policyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deletePolicy', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('deletePolicy', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('deletePolicy', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('deletePolicy', 'policyId', policyId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/policies/{policyId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"policyId"}}`, encodeURIComponent(String(policyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all policies for the specified organization and recording period.
         * @summary Returns all policies for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicies: async (organizationId: string, recordingPeriodId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getPolicies', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('getPolicies', 'recordingPeriodId', recordingPeriodId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/policies`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the specified policy for the specified organization and recording period.
         * @summary Updates the specified policy for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} policyId The id of the policy to use.
         * @param {IPolicyData} [iPolicyData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePolicy: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, policyId: string, iPolicyData?: IPolicyData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updatePolicy', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('updatePolicy', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('updatePolicy', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('updatePolicy', 'policyId', policyId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/policies/{policyId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"policyId"}}`, encodeURIComponent(String(policyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iPolicyData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoliciesApi - functional programming interface
 * @export
 */
export const PoliciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoliciesApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new policy for the given DEO.
         * @summary Creates a new policy for the given DEO.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {IPolicyData} [iPolicyData] Used to create a new policy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPolicy(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iPolicyData?: IPolicyData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPolicy(organizationId, recordingPeriodId, dataEntryObjectId, iPolicyData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the specified policy for the specified organization and recording period.
         * @summary Deletes the specified policy for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} policyId The id of the policy to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePolicy(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, policyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePolicy(organizationId, recordingPeriodId, dataEntryObjectId, policyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all policies for the specified organization and recording period.
         * @summary Returns all policies for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPolicies(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetPoliciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPolicies(organizationId, recordingPeriodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the specified policy for the specified organization and recording period.
         * @summary Updates the specified policy for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} policyId The id of the policy to use.
         * @param {IPolicyData} [iPolicyData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePolicy(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, policyId: string, iPolicyData?: IPolicyData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePolicy(organizationId, recordingPeriodId, dataEntryObjectId, policyId, iPolicyData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PoliciesApi - factory interface
 * @export
 */
export const PoliciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoliciesApiFp(configuration)
    return {
        /**
         * Creates a new policy for the given DEO.
         * @summary Creates a new policy for the given DEO.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {IPolicyData} [iPolicyData] Used to create a new policy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPolicy(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iPolicyData?: IPolicyData, options?: any): AxiosPromise<IPolicy> {
            return localVarFp.createPolicy(organizationId, recordingPeriodId, dataEntryObjectId, iPolicyData, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the specified policy for the specified organization and recording period.
         * @summary Deletes the specified policy for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} policyId The id of the policy to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePolicy(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, policyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePolicy(organizationId, recordingPeriodId, dataEntryObjectId, policyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all policies for the specified organization and recording period.
         * @summary Returns all policies for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicies(organizationId: string, recordingPeriodId: string, options?: any): AxiosPromise<IGetPoliciesResponse> {
            return localVarFp.getPolicies(organizationId, recordingPeriodId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the specified policy for the specified organization and recording period.
         * @summary Updates the specified policy for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} policyId The id of the policy to use.
         * @param {IPolicyData} [iPolicyData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePolicy(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, policyId: string, iPolicyData?: IPolicyData, options?: any): AxiosPromise<IPolicy> {
            return localVarFp.updatePolicy(organizationId, recordingPeriodId, dataEntryObjectId, policyId, iPolicyData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PoliciesApi - interface
 * @export
 * @interface PoliciesApi
 */
export interface PoliciesApiInterface {
    /**
     * Creates a new policy for the given DEO.
     * @summary Creates a new policy for the given DEO.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {IPolicyData} [iPolicyData] Used to create a new policy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApiInterface
     */
    createPolicy(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iPolicyData?: IPolicyData, options?: AxiosRequestConfig): AxiosPromise<IPolicy>;

    /**
     * Deletes the specified policy for the specified organization and recording period.
     * @summary Deletes the specified policy for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} policyId The id of the policy to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApiInterface
     */
    deletePolicy(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, policyId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns all policies for the specified organization and recording period.
     * @summary Returns all policies for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApiInterface
     */
    getPolicies(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig): AxiosPromise<IGetPoliciesResponse>;

    /**
     * Updates the specified policy for the specified organization and recording period.
     * @summary Updates the specified policy for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} policyId The id of the policy to use.
     * @param {IPolicyData} [iPolicyData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApiInterface
     */
    updatePolicy(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, policyId: string, iPolicyData?: IPolicyData, options?: AxiosRequestConfig): AxiosPromise<IPolicy>;

}

/**
 * PoliciesApi - object-oriented interface
 * @export
 * @class PoliciesApi
 * @extends {BaseAPI}
 */
export class PoliciesApi extends BaseAPI implements PoliciesApiInterface {
    /**
     * Creates a new policy for the given DEO.
     * @summary Creates a new policy for the given DEO.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {IPolicyData} [iPolicyData] Used to create a new policy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public createPolicy(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iPolicyData?: IPolicyData, options?: AxiosRequestConfig) {
        return PoliciesApiFp(this.configuration).createPolicy(organizationId, recordingPeriodId, dataEntryObjectId, iPolicyData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the specified policy for the specified organization and recording period.
     * @summary Deletes the specified policy for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} policyId The id of the policy to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public deletePolicy(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, policyId: string, options?: AxiosRequestConfig) {
        return PoliciesApiFp(this.configuration).deletePolicy(organizationId, recordingPeriodId, dataEntryObjectId, policyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all policies for the specified organization and recording period.
     * @summary Returns all policies for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public getPolicies(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig) {
        return PoliciesApiFp(this.configuration).getPolicies(organizationId, recordingPeriodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the specified policy for the specified organization and recording period.
     * @summary Updates the specified policy for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} policyId The id of the policy to use.
     * @param {IPolicyData} [iPolicyData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public updatePolicy(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, policyId: string, iPolicyData?: IPolicyData, options?: AxiosRequestConfig) {
        return PoliciesApiFp(this.configuration).updatePolicy(organizationId, recordingPeriodId, dataEntryObjectId, policyId, iPolicyData, options).then((request) => request(this.axios, this.basePath));
    }
}

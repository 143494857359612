/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IBaseTargetData } from '../models';
// @ts-ignore
import { ICreatePolicy409Response } from '../models';
// @ts-ignore
import { ICreateReferenceBudget404Response } from '../models';
// @ts-ignore
import { ICreateTarget404Response } from '../models';
// @ts-ignore
import { IDeleteTarget404Response } from '../models';
// @ts-ignore
import { IGetTargetsResponse } from '../models';
// @ts-ignore
import { ITarget } from '../models';
// @ts-ignore
import { IUpdateTarget404Response } from '../models';
/**
 * TargetsApi - axios parameter creator
 * @export
 */
export const TargetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new target for the given DEO.
         * @summary Creates a new target for the given DEO.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {IBaseTargetData} [iBaseTargetData] Create a new target
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTarget: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseTargetData?: IBaseTargetData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createTarget', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('createTarget', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('createTarget', 'dataEntryObjectId', dataEntryObjectId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/targets`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseTargetData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the specified target for the specified organization and recording period.
         * @summary Deletes the specified target for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} targetId The id of the target to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTarget: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, targetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteTarget', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('deleteTarget', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('deleteTarget', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'targetId' is not null or undefined
            assertParamExists('deleteTarget', 'targetId', targetId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/targets/{targetId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"targetId"}}`, encodeURIComponent(String(targetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all targets for the specified organization and recording period.
         * @summary Returns all targets for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargets: async (organizationId: string, recordingPeriodId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getTargets', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('getTargets', 'recordingPeriodId', recordingPeriodId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/targets`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the specified target for the specified organization and recording period.
         * @summary Updates the specified target for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} targetId The id of the target to use.
         * @param {IBaseTargetData} [iBaseTargetData] Update an existing target
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTarget: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, targetId: string, iBaseTargetData?: IBaseTargetData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateTarget', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('updateTarget', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('updateTarget', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'targetId' is not null or undefined
            assertParamExists('updateTarget', 'targetId', targetId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/targets/{targetId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"targetId"}}`, encodeURIComponent(String(targetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseTargetData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TargetsApi - functional programming interface
 * @export
 */
export const TargetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TargetsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new target for the given DEO.
         * @summary Creates a new target for the given DEO.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {IBaseTargetData} [iBaseTargetData] Create a new target
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTarget(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseTargetData?: IBaseTargetData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITarget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTarget(organizationId, recordingPeriodId, dataEntryObjectId, iBaseTargetData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the specified target for the specified organization and recording period.
         * @summary Deletes the specified target for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} targetId The id of the target to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTarget(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, targetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTarget(organizationId, recordingPeriodId, dataEntryObjectId, targetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all targets for the specified organization and recording period.
         * @summary Returns all targets for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTargets(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetTargetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTargets(organizationId, recordingPeriodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the specified target for the specified organization and recording period.
         * @summary Updates the specified target for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} targetId The id of the target to use.
         * @param {IBaseTargetData} [iBaseTargetData] Update an existing target
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTarget(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, targetId: string, iBaseTargetData?: IBaseTargetData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITarget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTarget(organizationId, recordingPeriodId, dataEntryObjectId, targetId, iBaseTargetData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TargetsApi - factory interface
 * @export
 */
export const TargetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TargetsApiFp(configuration)
    return {
        /**
         * Creates a new target for the given DEO.
         * @summary Creates a new target for the given DEO.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {IBaseTargetData} [iBaseTargetData] Create a new target
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTarget(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseTargetData?: IBaseTargetData, options?: any): AxiosPromise<ITarget> {
            return localVarFp.createTarget(organizationId, recordingPeriodId, dataEntryObjectId, iBaseTargetData, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the specified target for the specified organization and recording period.
         * @summary Deletes the specified target for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} targetId The id of the target to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTarget(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, targetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTarget(organizationId, recordingPeriodId, dataEntryObjectId, targetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all targets for the specified organization and recording period.
         * @summary Returns all targets for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargets(organizationId: string, recordingPeriodId: string, options?: any): AxiosPromise<IGetTargetsResponse> {
            return localVarFp.getTargets(organizationId, recordingPeriodId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the specified target for the specified organization and recording period.
         * @summary Updates the specified target for the specified organization and recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} targetId The id of the target to use.
         * @param {IBaseTargetData} [iBaseTargetData] Update an existing target
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTarget(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, targetId: string, iBaseTargetData?: IBaseTargetData, options?: any): AxiosPromise<ITarget> {
            return localVarFp.updateTarget(organizationId, recordingPeriodId, dataEntryObjectId, targetId, iBaseTargetData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TargetsApi - interface
 * @export
 * @interface TargetsApi
 */
export interface TargetsApiInterface {
    /**
     * Creates a new target for the given DEO.
     * @summary Creates a new target for the given DEO.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {IBaseTargetData} [iBaseTargetData] Create a new target
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetsApiInterface
     */
    createTarget(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseTargetData?: IBaseTargetData, options?: AxiosRequestConfig): AxiosPromise<ITarget>;

    /**
     * Deletes the specified target for the specified organization and recording period.
     * @summary Deletes the specified target for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} targetId The id of the target to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetsApiInterface
     */
    deleteTarget(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, targetId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns all targets for the specified organization and recording period.
     * @summary Returns all targets for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetsApiInterface
     */
    getTargets(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig): AxiosPromise<IGetTargetsResponse>;

    /**
     * Updates the specified target for the specified organization and recording period.
     * @summary Updates the specified target for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} targetId The id of the target to use.
     * @param {IBaseTargetData} [iBaseTargetData] Update an existing target
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetsApiInterface
     */
    updateTarget(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, targetId: string, iBaseTargetData?: IBaseTargetData, options?: AxiosRequestConfig): AxiosPromise<ITarget>;

}

/**
 * TargetsApi - object-oriented interface
 * @export
 * @class TargetsApi
 * @extends {BaseAPI}
 */
export class TargetsApi extends BaseAPI implements TargetsApiInterface {
    /**
     * Creates a new target for the given DEO.
     * @summary Creates a new target for the given DEO.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {IBaseTargetData} [iBaseTargetData] Create a new target
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetsApi
     */
    public createTarget(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseTargetData?: IBaseTargetData, options?: AxiosRequestConfig) {
        return TargetsApiFp(this.configuration).createTarget(organizationId, recordingPeriodId, dataEntryObjectId, iBaseTargetData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the specified target for the specified organization and recording period.
     * @summary Deletes the specified target for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} targetId The id of the target to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetsApi
     */
    public deleteTarget(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, targetId: string, options?: AxiosRequestConfig) {
        return TargetsApiFp(this.configuration).deleteTarget(organizationId, recordingPeriodId, dataEntryObjectId, targetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all targets for the specified organization and recording period.
     * @summary Returns all targets for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetsApi
     */
    public getTargets(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig) {
        return TargetsApiFp(this.configuration).getTargets(organizationId, recordingPeriodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the specified target for the specified organization and recording period.
     * @summary Updates the specified target for the specified organization and recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} targetId The id of the target to use.
     * @param {IBaseTargetData} [iBaseTargetData] Update an existing target
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetsApi
     */
    public updateTarget(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, targetId: string, iBaseTargetData?: IBaseTargetData, options?: AxiosRequestConfig) {
        return TargetsApiFp(this.configuration).updateTarget(organizationId, recordingPeriodId, dataEntryObjectId, targetId, iBaseTargetData, options).then((request) => request(this.axios, this.basePath));
    }
}

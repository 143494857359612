import { FC, ReactNode, useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DialogCloseButton } from "../dialog-button.components";

interface IInfoDialogProps {
  open: boolean;
  onClose: VoidFunction;
  title: ReactNode;
  content: ReactNode;
}

export const InfoDialog: FC<IInfoDialogProps> = ({ open, onClose, title, content }) => {
  const { t } = useTranslation("buttons");

  return (
    <Dialog open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <DialogCloseButton onClick={() => onClose()}>{t("close")}</DialogCloseButton>
      </DialogActions>
    </Dialog>
  );
};

export const InfoDialogText: FC<IInfoDialogProps> = ({ content, ...props }) => {
  const wrappedBody = useMemo(() => <DialogContentText>{content}</DialogContentText>, [content]);
  return <InfoDialog content={wrappedBody} {...props} />;
};
